import { InputNumber, Select, Space } from 'antd'
import React from 'react'

export default function PriceInput({ value = {}, onChange }) {
  const onCurrencyChange = (code) => {
    onChange?.({
      value: value?.value,
      currency_code: code,
    })
  }

  const onPriceChange = (newValue) => {
    onChange?.({
      value: newValue,
      currency_code: value?.currency_code,
    })
  }

  return (
    <Space>
      <InputNumber step={0.01} value={value?.value} onChange={onPriceChange}/>
      <Select value={value?.currency_code} onChange={onCurrencyChange} placeholder="Currency">
        <Select.Option value="USD">USD</Select.Option>
        <Select.Option value="EUR">EUR</Select.Option>
      </Select>
    </Space>
  )
}